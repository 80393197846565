<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="ylLists" :loading="loading" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="ylLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/OTHERDetail', query: { id: record.SHEBAODATA_OTHERS_ID, num: record.xuhao, type: 'edit', ctype: ctype } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="add-item">
                <div @click="$router.push({ path: '/ReportForms/OTHERDetail', query: { type: 'add', id: '', num: '', ctype: ctype } })">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;">增加一行
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
  { title: '序号', align: 'center', dataIndex: 'xuhao', key: 'xuhao' },
  { title: '结算期', align: 'center', dataIndex: 'jiesuanqi', key: 'jiesuanqi' },
  { title: '险种类型', align: 'center', dataIndex: 'xianzhongleixing', key: 'xianzhongleixing' },
  { title: '缴费标志', align: 'center', dataIndex: 'jiaofeibiaozhi', key: 'jiaofeibiaozhi' },
  { title: '缴费总额', align: 'center', dataIndex: 'jiaofeizonge', key: 'jiaofeizonge' },
  { title: '单位缴费金额', align: 'center', dataIndex: 'danweijiaofeijine', key: 'danweijiaofeijine' },
  { title: '个人缴费金额', align: 'center', dataIndex: 'gerenjiaofeijine', key: 'gerenjiaofeijine' },
  { title: '到账日期', align: 'center', dataIndex: 'daozhangriqi', key: 'daozhangriqi' },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    fixed: 'right',
    width: 100,
    slots: { customRender: 'action' }
  }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'sbItem',
            'addXXStatus',
            'y'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getOtherList(this.y)
            }
        },
        y: function (newVal) {
          if (newVal) {
            this.getOtherList(this.y)
          }
        },
        sbItem: function (newVal) {
            if (newVal) {
                if (newVal === 'YL') {
                    this.ctype = 'yiliao'
                } else if (newVal === 'GS') {
                    this.ctype = 'gongshang'
                } else if (newVal === 'SY') {
                    this.ctype = 'shengyu'
                } else if (newVal === 'GJJ') {
                    this.ctype = 'gongjijin'
                }
                this.getOtherList(this.y)
            }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            ylLists: [],
            pagination: {},
            page: 1,
            pageCount: 10,
            ctype: ''
        }
    },
    methods: {
        // 获取医疗保险(工伤、生育、住房公积金)列表
        getOtherList (y) {
            this.loading = true
            this.ylLists = []
            this.$store.dispatch('getOtherList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime(), CTYPE: this.ctype }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.ylLists.push({
                                'key': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'SHEBAODATA_OTHERS_ID': datas[i].SHEBAODATA_OTHERS_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'jiesuanqi': datas[i].TERM,
                                'xianzhongleixing': datas[i].XIANZHONGTYPE,
                                'jiaofeibiaozhi': datas[i].JIAOFEISTATUS,
                                'jiaofeizonge': datas[i].JIAOFEITOTAL,
                                'danweijiaofeijine': datas[i].UNITJIAOFEI,
                                'gerenjiaofeijine': datas[i].PERSONALJIAOFEI,
                                'daozhangriqi': datas[i].RECEIVEDATE
                            })
                        }
                    } else {
                        this.pagination.total = 0
                        this.ylLists = []
                    }
                } else {
                    this.pagination.total = 0
                    this.ylLists = []
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            }).catch(e => {
                this.loading = false
                this.$message.error('系统异常，获取列表失败，请稍后重试！')
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getOtherList(this.y)
        },
        // 删除单条医疗保险(工伤、生育、住房公积金)
        onDel (item) {
            this.$store.dispatch('deleteOtherItem', { SHEBAODATA_OTHERS_ID: item.SHEBAODATA_OTHERS_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getOtherList(this.y)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        if (this.$route.query.ctype === 'YL') {
            this.ctype = 'yiliao'
        } else if (this.$route.query.ctype === 'GS') {
            this.ctype = 'gongshang'
        } else if (this.$route.query.ctype === 'SY') {
            this.ctype = 'shengyu'
        } else if (this.$route.query.ctype === 'GJJ') {
            this.ctype = 'gongjijin'
        }
        this.getOtherList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .add-item{
            margin-top: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>
